import { useState, useContext } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Drawer,
  Button,
  Divider,
  TextInput,
  Text,
  Textarea,
  Space,
  LoadingOverlay
} from "@mantine/core";
import styled from "styled-components";
import axiosInstance from "../../services/axios";
import { FileUpload } from "../Dropzone/Dropzone";
import { AuthContext } from "../../contexts/AuthContext";

// import { LoadingBar } from "@mantine/core";


const DocumentList = ({ documents, onRemove }) => (
  <DocumentsContainer>
    {documents && documents.length > 0 ? (
      documents.map((document, index) => (
        <DocumentCard key={document.name}>
          <a href={URL.createObjectURL(document)} target="_blank" rel="noopener noreferrer">
          {formatDocumentName(document.name.length > 20 ? `${document.name.substring(0, 17)}...` : document.name)}
          </a>
          <Button color="red" onClick={() => onRemove(index)}>Remove</Button>
        </DocumentCard>
      ))
    ) : (
      <Text>No documents available</Text>
    )}
  </DocumentsContainer>
);

const formatDocumentName = (key) => {
  if (!key) return '';
  
  const segments = key.split('-');
  const lastSegment = segments[segments.length - 1];
  return lastSegment;
};

export const AddNewProjectModal = ({fetchData}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [name, setName] = useState("");
  const [uri, setURI] = useState("");
  const [description, setDescription] = useState("");
  const [ownedBy, setOwnedBy] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    name: "",
    uri: "",
    description: "",
    username: "",
    password: "",
    files: "",
  });
  
  const { user } = useContext(AuthContext);
  
  console.log(user, 'userId ne create')
  const validate = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required";
    if (!uri.trim()) {
      newErrors.uri = "URL is required";
    }
    if (!description.trim()) newErrors.description = "Description is required";
    if (!username.trim()) newErrors.username = "Username is required";
    if (!password.trim()) newErrors.password = "Password is required";
    if (uploadedFiles.some(file => file.type !== "application/pdf")) {
      newErrors.files = "Only PDF files are allowed, and at least one file must be uploaded";
    }
    
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const createInstance = async () => {
    const isValid = validate();
    if (!isValid) return;
    setLoading(true);
    try {
      const instanceData = new FormData();  // Using FormData to handle file uploads
      instanceData.append('name', name);
      instanceData.append('uri', uri);
      instanceData.append('description', description);
      instanceData.append('username', username);
      instanceData.append('password', password);
      instanceData.append('userId', user.userId);  // Assuming that user object has a userId field
  
      // Adding files to FormData
      if (uploadedFiles && uploadedFiles.length > 0) {
        uploadedFiles.forEach(file => {
          instanceData.append('document', file);  // 'files' should match the field expected in the backend
        });
      }
  
      const response = await axiosInstance.post("/projects/create", instanceData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Encoding': 'gzip',
        },
      });
  
      if (response.status === 200) {
        // Handle success, maybe updating the UI or redirecting the user
        closeAndRefresh();
      } else {
        setErrorMessage('Failed to create instance.');
      }
    } catch (error) {
      setErrorMessage(`Failed to create instance. ${error.message}`);
    }
    setLoading(false);
  };

  const removeFile = (index) => {
    setUploadedFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };
  

  const resetState = () => {
    setName("");
    setURI("");
    setDescription("");
    setOwnedBy("");
    setUsername("");
    setPassword("");
    setErrorMessage("");
    setUploadedFiles([]);
    setErrors({
      name: "",
      uri: "",
      description: "",
      username: "",
      password: "",
      files: "",
    });
  };
  


const closeAndRefresh = () => {
  close();
  resetState();

  fetchData();
};


  return (
    <>
      <Drawer
        position="right"
        opened={opened}
        onClose={close}
        title="Create New Project"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
      <LoadingOverlay visible={loading} />

        <Divider />
        <Space h={20} />
        <ProjectForm>
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Project Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}

          />
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errors.description}

          />
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="URL"
            placeholder="URL"
            value={uri}
            onChange={(e) => setURI(e.target.value)}
            error={errors.uri}

          />
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.username}

          />
          <TextInput
            style={{ paddingBottom: "14px" }}
            type="text"
            label="Password"
            placeholder="At least 8 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}

          />
          
        </ProjectForm>
        
        <FileUpload 
  onFilesAdded={(files) => { 
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
    setErrors(prevErrors => ({...prevErrors, files: ""}));
  }}
  onFileRejected={(files) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      files: "PDF files must be less than 3.5MB",
    }));
  }}
  // error={errors.files}
/>

<DocumentList documents={uploadedFiles} onRemove={removeFile} />
{/* {uploadedFiles.map((file, index) => (
  <div key={index}>
    <Text>{file.name}</Text>
    <Button onClick={() => removeFile(index)} size="xs" color="red" style={{ marginLeft: '10px' }}>Remove</Button>
  </div>
))} */}

        <Divider />
        <Space h={20} />
        <ButtonsContainer>
          <Button
            onClick={close}
            style={{
              width: "14em",
              height: "3em",
              borderRadius: "4px",
              backgroundColor: "#cfcdd6",
              color: "#08024d",
              fontWeight: 700,
            }}
          >
            Cancel
          </Button>
          <Button
              onClick={createInstance}
              disabled={loading}
              style={{
                  width: "14em",
                  height: "3em",
                  borderRadius: "4px",
                  backgroundColor: loading ? "#cfcdd6" : "#08024d",  // Change background color when loading
                  fontWeight: 700,
              }}
          >
              Create
          </Button>
        </ButtonsContainer>
      </Drawer>
        {user?.role === 'superadmin' ?  
        <Button
        onClick={open}
        style={{
          width: "10em",
          borderRadius: "4px",
          backgroundColor: "#08024d",
          fontWeight: 700,
        }}
      >
        + New Project
      </Button> : 
      ""}      
    </>
  );
};

const ProjectForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DocumentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;