import { useEffect, useState, useContext } from "react";
import { EditUserModal } from "../../components/EditUserModal/EditUserModal";
import { TeamDetails } from "../../components/TeamDetails/TeamDetails";
import { Layout } from "../../layout/Layout";
import { styled } from "styled-components";
import { AppShell, Pagination } from "@mantine/core";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "../../services/axios";

export const TeamPage = () => {
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({
    user: null,
    open: false,
  });

  const [userUpdated, setUserUpdated] = useState(false);

  const totalPages = Math.ceil(totalUsers / perPage);

  const handleEditUser = (user) => {
    setSelectedUser({
      user: user,
      open: true,
    });
  };

  const handleUserUpdated = () => {
    setUserUpdated(!userUpdated);
  };
  const fetchUsers = async () => {
    setLoading(true);
    try {
      let endpoint =
        user?.role === "superadmin"
          ? "/users/all"
          : `/users/users-by-client/${user?.userId}`;
      const response = await axios.get(endpoint);
      setUsers(response.data);
      setTotalUsers(response.data.length);
    } catch (error) {
      console.error(`Error fetching users: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchUsers();
    } else {
      setLoading(true);
    }
  }, [user, userUpdated]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <TeamDetails
        perPage={perPage}
        currentPage={currentPage}
        onEditUser={handleEditUser}
        userUpdated={handleUserUpdated}
        data={fetchUsers}
        setTotalUsers={setTotalUsers}
        users={users.slice((currentPage - 1) * perPage, currentPage * perPage)} // Pass only the users for the current page
        loading={loading}
      />
      <EditUserModal
        opened={selectedUser.open}
        onClose={() => {
          setSelectedUser({
            user: null,
            open: false,
          });
          setUserUpdated(!userUpdated);
        }}
        currentUser={selectedUser.user}
      />
      <div style={{ paddingTop: "50px" }}></div>
      <AppShell.Footer h={60}>
        <PaginationDiv>
          <Pagination
            color="#08024d"
            onChange={handlePageChange}
            value={currentPage}
            total={totalPages}
          />
        </PaginationDiv>
      </AppShell.Footer>
    </Layout>
  );
};

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: "10em";
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
