import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Button,
  Divider,
  Text,
  TextInput,
  Textarea,
  Space,
  MultiSelect,
  LoadingOverlay
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil } from "@tabler/icons-react";
import styled from "styled-components";
import axiosInstance from "../../services/axios"; // Adjust the import according to your file structure
import { AuthContext } from "../../contexts/AuthContext";

export const EditTeamProfile = ({ team, onTeamUpdated }) => {

  console.log(team,'teaminModal')
  // const [opened, { open, close }] = useDisclosure(false);
  const [opened, {open,close}] = useDisclosure(false);
  const [name, setName] = useState(team?.name);
  const [description, setDescription] = useState(team?.description);
  const [members, setMembers] = useState(team?.members);
  const [selectedProjects, setSelectedProjects] = useState(team?.projects);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {user} = useContext(AuthContext)

  const usersOptions = users.map(user => ({
    value: user?.userId,
    label: user?.username || "",
  }));

  console.log(usersOptions,'usersOptions')

  const projectsOptions = projects.map(project => ({
    value: project?.projectId,
    label: project?.name || "",
  }));
  console.log(projectsOptions,'projectsOptions')



  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, []);
  const fetchUsers = async () => {
    try {
      let endpoint = ''

      if(user?.role == 'superadmin'){
        endpoint = '/users/all'
      }else{
        endpoint = `/users/users-by-client/${user?.userId}`
      }
      const response = await axiosInstance.get(endpoint);
      setUsers(response.data);
    } catch (error) {
      console.error(`Error fetching users: ${error}`);
    }
  };

  const fetchProjects = async () => {
    try {
      let response;
      if (user.role === 'client') {
        response = { data: user.projects };
      } else {
        response = await axiosInstance.get("/projects/all");
      }
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const updateTeam = async () => {
    setLoading(true);
    setErrorMessage("");
  
    try {
      const updatedTeamData = {
        name,
        description,
        members,
        projects: selectedProjects,
      };
  
      const response = await axiosInstance.put(`/teams/${team.teamId}`, updatedTeamData, {
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (response.status === 200) {
        closeAndRefresh(); // Close modal and refresh data
        if (onTeamUpdated) onTeamUpdated(); // Call the callback function if provided
      } else {
        setErrorMessage('Failed to update team.');
      }
    } catch (error) {
      setErrorMessage(`Failed to update team. ${error.message}`);
    }
    setLoading(false);
  };
  

  const closeAndRefresh = () => {
    close();
    // fetchData();
  };

  useEffect(() => {
    if (team) {
      setName(team?.name || '');
      setDescription(team?.description || '');
  
      // Filter out null members, then map over the filtered array to get user IDs
      const memberIds = (team.members || []).filter(member => member !== null).map(member => member.userId);
      setMembers(memberIds);
  
      // Filter out null projects, then map over the filtered array to get project IDs
      const projectIds = (team.projects || []).filter(project => project !== null).map(project => project.projectId);
      setSelectedProjects(projectIds);
    }
  }, [team]);  // Adding team as a dependency so the effect will re-run if the team prop changes

  
  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, [user]);  // Added user as a dependency


  return (
    <>
      <Drawer
        position="right"
        opened={opened}
        onClose={close}
        title="Edit Team Profile"
        overlayProps={{ 
          backgroundOpacity: 0.5, blur: 4 
        }}
      >
      <LoadingOverlay visible={loading} /> {/* Add LoadingOverlay here */}

        <Divider />
        <Space h={20} />
        <ProjectForm>
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Team Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Textarea
            style={{ paddingBottom: "14px" }}
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <MultiSelect
            checkIconPosition="left"
            data={usersOptions}
            value={members}
            onChange={setMembers}
            label="Users"
            placeholder="Search"
            searchable
          />
          <MultiSelect
            checkIconPosition="left"
            data={projectsOptions}
            value={selectedProjects}
            onChange={setSelectedProjects}
            label="Projects"
            placeholder="Search"
            searchable
          />
        </ProjectForm>
        <Divider />
        <Space h={20} />
        <ButtonsContainer>
          <Button
            onClick={close}
            style={{
              width: "14em",
              height: "3em",
              borderRadius: "4px",
              backgroundColor: "#cfcdd6",
              color: "#08024d",
              fontWeight: 700,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={updateTeam}
            disabled={loading}
            style={{
              width: "14em",
              height: "3em",
              borderRadius: "4px",
              backgroundColor: loading ? "#cfcdd6" : "#08024d",
              fontWeight: 700,
            }}
          >
            Save
          </Button>
        </ButtonsContainer>
        {errorMessage && <Text color="red">{errorMessage}</Text>}
      </Drawer>
      <IconPencil onClick={open} size={22} style={{ cursor: "pointer" }} />
    </>
  );
};

const ProjectForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
