import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../../services/axios";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Drawer, TextInput, Text, Space, Divider,MultiSelect, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import styled from "styled-components";
import axios from "../../services/axios"



export const AddNewCrewModal= ({ fetchData, onTeamUpdated }) => {
  const { user } = useContext(AuthContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  const [selectedProjects,setSelectedProjects] = useState([])
  const [formErrors, setFormErrors] = useState({});

  const [filteredProjects, setFilteredProjects] = useState([]);

  const filterProjects = () => {
    if (user?.role === 'client') {
      const userProjectIds = new Set(user.projects.map(p => p.projectId));
      setFilteredProjects(projects.filter(p => userProjectIds.has(p.projectId)));
    } else {
      setFilteredProjects(projects);
    }
  };


  const validateForm = () => {
    let errors = {};
    if (!name.trim()) errors.name = "Team Name is required";
    if (!description.trim()) errors.description = "Description is required";
    if (members.length === 0) errors.members = "At least one member is required";
    if (selectedProjects.length === 0) errors.selectedProjects = "At least one project is required";
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  
  const usersOptions = users.map(user => ({
    value: user.userId,
    label: user.username || '',
  }));

  const projectsOptions = filteredProjects.map(project => ({
    value: project.projectId,
    label: project.name || '', 
  }));

  

  const createTeam = async () => {
    const isValid = validateForm();
    if (!isValid) return;
    setLoading(true);
    
    try {
      const teamData = {
        name,
        description,
        projects: selectedProjects,
        clientId: user.userId, 
        members: members,
      };

      const response = await axiosInstance.post("/teams", teamData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        if (onTeamUpdated) onTeamUpdated();
        
        closeAndRefresh();

      } else {
        setErrorMessage('Failed to create team.');
      }
    } catch (error) {
      setErrorMessage(`Failed to create team. ${error.message}`);
    }
    setLoading(false);
  };
  const fetchUsers = async () => {
    try {
      let endpoint = ''

      if(user?.role == 'superadmin'){
        endpoint = '/users/all'
      }else{
        endpoint = `/users/users-by-client/${user?.userId}`
      }
      // let endpoint = "/client-users"; 
      // if (currentUser?.user.role === "superadmin") {
      //   endpoint = "/clients";
      // }
      const response = await axios.get(endpoint);
      // const response2 = await axios.get(anotherEndpoint);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching users: ${error}`);
      setLoading(false);
    }
  };
  
  const closeAndRefresh = () => {
    close();
    if (typeof fetchData === 'function') {
      fetchData();
    }
  };
  
  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get(`/projects/all`);
      console.log(response?.data,'PROJECTS')
      setProjects(response?.data);  
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    console.log(selectedProjects, 'Selected Projects in useEffect');
  }, [selectedProjects]);

  useEffect(() => {
    fetchUsers();
    fetchProjects();
  },[])

  useEffect(() => {
    filterProjects();
  }, [user, projects])

  return (
    <>
      <Drawer
        position="right"
        opened={opened}
        onClose={close}
        title="Create New Team"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <LoadingOverlay visible={loading} />
        <Divider />
        <Space h={20} />
        <ProjectForm>
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Team Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={formErrors.name}

          />
          <TextInput
            style={{ paddingBottom: "14px" }}
            label="Description"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={formErrors.description}
            />
          <MultiSelect
            checkIconPosition="left"
            data={usersOptions}
            value={members}
            onChange={setMembers}
            pb={10}
            label="Users"
            description="Access user to projects"
            placeholder="Search"
            searchable
            error={formErrors.members}

          />
        <MultiSelect
          checkIconPosition="left"
          data={projectsOptions}
          value={selectedProjects} 
          onChange={setSelectedProjects} 
          pb={310}
          label="Projects"
          description="Select projects for the team"
          placeholder="Search"
          searchable
          error={formErrors.selectedProjects}

        />
        </ProjectForm>
        <Divider />
        <Space h={20} />
        <ButtonsContainer>
          <Button
            onClick={close}
            style={{
              width: "14em",
              height: "3em",
              borderRadius: "4px",
              backgroundColor: "#cfcdd6",
              color: "#08024d",
              fontWeight: 700,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={createTeam}
            disabled={loading}
            style={{
              width: "14em",
              height: "3em",
              borderRadius: "4px",
              backgroundColor: loading ? "#cfcdd6" : "#08024d",  
              fontWeight: 700,
            }}
          >
            Create
          </Button>
        </ButtonsContainer>
        {errorMessage && <Text color="red">{errorMessage}</Text>}
      </Drawer>
      {user?.role == 'team_member' ? "" : 
        <Button
        onClick={open}
        style={{
          width: "10em",
          borderRadius: "4px",
          backgroundColor: "#08024d",
          fontWeight: 700,
        }}
      >
        + New Team
      </Button>
      }

    </>
  );
};

const ProjectForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
