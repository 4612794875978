import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Button, Space, Text, LoadingOverlay } from '@mantine/core';
import Axios from "../../services/axios"

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams(); // Extracting the token from the URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
  
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      setLoading(false);
      return;
    }
  
    try {
      const response = await Axios.patch(`/users/reset-password/${token}`, { password });
      if (response.status === 200) {
        setError('Password has been reset successfully!');
        setTimeout(() => {
          navigate('/login')
        }, "1500");
      } else {
        setError('Failed to reset password. Please try again later.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Wrapper>
      <LoadingOverlay visible={loading} />
      <img
        width={240}
        height={50}
        src="../../assets/dialectIQ.png"
        alt="dialectIQ"
      />
      <Space h={30} />
      <Text size="xl" weight={500}>Reset Password</Text>
      <Text size="sm" color="dimmed">Please enter your new password</Text>
      <Space h={20} />
      <Input
        style={{ width: "340px" }}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
        disabled={loading}
      />
      <Space h={20} />
      <Input
        style={{ width: "340px" }}
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirm your password"
        disabled={loading}
      />
      <Space h={20} />
      <Button
        style={{
          width: "340px",
          height: "45px",
          backgroundColor: "#08024d",
          fontWeight: 700,
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        Reset Password
      </Button>
      {error && (
        <Text color="red" align="center" mt="sm">
          {error}
        </Text>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 17rem;
  position: relative;
`;

export default ResetPasswordPage;
