import { Group, Text, rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";

export function FileUpload({ onFilesAdded, onFileRejected, error, ...props }) {
    return (
        <>
                <Dropzone
            onDrop={(files) => {
                onFilesAdded(files);
            }}
            onReject={(files) => {
                onFileRejected(files);
            }}
            maxSize={3.5 * 1024 * 1024} // 3.5MB in bytes

            {...props}
        >
            <Group
                justify="center"
                gap="xl"
                mih={120}
                style={{ pointerEvents: "none" }}
            >
                <Dropzone.Accept>
                    <IconUpload
                        style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-blue-6)",
                        }}
                        stroke={1.5}
                    />
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <IconX
                        style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-red-6)",
                        }}
                        stroke={1.5}
                    />
                </Dropzone.Reject>
                <Dropzone.Idle>
                    <IconPhoto
                        style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-dimmed)",
                        }}
                        stroke={1.5}
                    />
                </Dropzone.Idle>
                <div>
                    <Text size="xl" inline>
                        Choose a file
                    </Text>
                    <Text size="md" color="dimmed" inline>
                        or drag and drop it here
                    </Text>
                </div>
            </Group>
        </Dropzone>
                {error && <Text color="red" size="sm" align="center" style={{ marginTop: '10px' }}>{error}</Text>}
        </>

    );
}