import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Space, Text, LoadingOverlay } from '@mantine/core';
import Axios from "../../services/axios"

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
  
    try {
      const response = await Axios.post('/users/reset-password', { email });
      if (response.status === 200) {
        // You might want to navigate the user to another page or show a success message here
        // navigate('/some-route');

        setTimeout(() => {
          navigate('/login')
        }, "1500");

  
        setError('Email sent successfully. Please check your inbox for further instructions.');
      }
    } catch (error) {
      console.error('Error sending reset email:', error);
      console.log(error,'errorr')
      setError(`Failed to send reset email. ${error?.response?.data?.error}`);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Wrapper>
      <LoadingOverlay visible={loading} />
      <img
        width={240}
        height={50}
        src="../../assets/dialectIQ.png"
        alt="dialectIQ"
      />
      <Space h={30} />
      <Text size="xl" weight={500}>Forgot Password</Text>
      <Text size="sm" color="dimmed">Please enter your email and you'll receive instructions</Text>
      <Space h={20} />
      <Input
        style={{ width: "340px" }}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        disabled={loading}
      />
      <Space h={20} />
      <Button
        style={{
          width: "340px",
          height: "45px",
          backgroundColor: "#08024d",
          fontWeight: 700,
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        Send Reset Email
      </Button>
      {error && (
        <Text color="red" align="center" mt="sm">
          {error}
        </Text>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 17rem;
  position: relative;
`;

export default ForgotPasswordPage;
