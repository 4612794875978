import { useState, useContext } from "react";
import { TextInput, Card, Text, Loader, Button } from "@mantine/core";
import { IconLink, IconPencil, IconSearch } from "@tabler/icons-react";
import { styled } from "styled-components";
import { AddNewProjectModal } from "../AddNewProjectModal/AddNewProjectModal";
import { DeletProjectModal } from "../DeleteProjectModal/DeleteProjectModal";
import { AuthContext } from "../../contexts/AuthContext";

export const HomeProjects = ({
  onEditProject,
  perPage = 6,
  currentPage,
  Idata,
  loading,
  fetchData,
}) => {
  const handleExploreClick = (itemUri) => {
    const bloomUrl = `https://bloom.neo4j.io/index.html?connectURL=${encodeURIComponent(
      itemUri
    )}`;
    window.open(bloomUrl, "_blank");
  };

  const [searchInput, setSearchInput] = useState("");
  const { user } = useContext(AuthContext);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const formatDocumentName = (key) => {
    if (!key) return "";

    const segments = key.split("-");
    const lastSegment = segments[segments.length - 1];
    return lastSegment;
  };

  let projectsToDisplay = Idata;

  // If the user role is Client, filter the projects based on user.user.projects
  if (user?.role === "client" || user?.role === "team_member") {
    const userProjectIds = new Set(user?.projects.map((p) => p.projectId));
    projectsToDisplay = Idata.filter((project) =>
      userProjectIds.has(project.projectId)
    );
  }

  const filteredProjects = projectsToDisplay.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const start = (currentPage - 1) * perPage;
  const end = start + perPage;
  const displayedProjects = filteredProjects.slice(start, end);

  return (
    <Wrapper>
      {loading ? (
        <StyledLoader>
          <Loader size={60} color="#08024d" />
        </StyledLoader>
      ) : (
        <>
          <ProjectHeader>
            <ProjectsTitle>Projects</ProjectsTitle>
            <NewProject>
              <TextInput
                style={{ width: "10em" }}
                leftSection={<IconSearch size={12} />}
                placeholder="Search"
                searchable
                value={searchInput} // Link the search input value to the state
                onChange={handleSearchChange}
              />
              <AddNewProjectModal fetchData={fetchData} />
            </NewProject>
          </ProjectHeader>
          <ProjectCards>
            {displayedProjects.map((item, index) => (
              <Card
                withBorder
                key={index}
                style={{
                  marginBottom: 16,
                  width: { base: 80, sm: 200, lg: 260 },
                }}
              >
                <Card.Section withBorder style={{ padding: "10px" }}>
                  <MainSection>
                    <Text
                      style={{
                        padding: "8px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      {item?.name}
                    </Text>
                    <MainSectionButtons>
                      {user?.role == "superadmin" ? (
                        <>
                          <IconPencil
                            onClick={() => onEditProject(item)}
                            size={22}
                            style={{ cursor: "pointer" }}
                            refreshData={fetchData}
                          />
                          <DeletProjectModal
                            instanceId={item.projectId}
                            instanceName={item.state}
                            refreshData={fetchData}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </MainSectionButtons>
                  </MainSection>
                </Card.Section>
                <Card.Section style={{ padding: "8px" }} withBorder>
                  <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                    Description
                  </Text>
                  <Text
                    style={{
                      fontWeight: 400,
                      fontSize: "13px",
                      paddingBottom: "5px",
                    }}
                  >
                    {item.description}
                  </Text>
                  <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                    Owned By:
                  </Text>
                  <Text
                    style={{
                      fontWeight: 400,
                      fontSize: "13px",
                      paddingBottom: "5px",
                    }}
                  >
                    Dialectiq
                  </Text>
                </Card.Section>
                <Card.Section style={{ padding: "8px" }} withBorder>
                  <Credentials>
                    <div>
                      <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                        Username{" "}
                        <span style={{ fontWeight: 400 }}>{item.username}</span>
                      </Text>
                    </div>
                    <div>
                      <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                        Password{" "}
                        <span style={{ fontWeight: 400 }}>{item.password}</span>
                      </Text>
                    </div>
                  </Credentials>
                </Card.Section>
                <Card.Section style={{ padding: "10px" }}>
                  <Button
                    leftSection={<IconLink size={15} />}
                    onClick={() => handleExploreClick(item.uri)}
                    style={{
                      backgroundColor: "#dfdee9",
                      height: "30px",
                      marginRight: "5px",
                      fontSize: "12px",
                      color: "#08024d",
                    }}
                  >
                    Explore
                  </Button>
                  {item.documents.map((el, docIndex) => (
                    <a
                      href={el?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        leftSection={<IconLink size={15} />}
                        style={{
                          backgroundColor: "#dfdee9",
                          height: "30px",
                          marginRight: "5px",
                          fontSize: "12px",
                          color: "#08024d",
                        }}
                      >
                        {formatDocumentName(el?.key)}
                      </Button>
                    </a>
                  ))}
                </Card.Section>
              </Card>
            ))}
          </ProjectCards>
        </>
      )}
    </Wrapper>
  );
};

const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MainSectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
`;

const Credentials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
`;

const ProjectCards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  padding-top: 70px;
  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const NewProject = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    flex-direction: row;
    padding-bottom: 20px;
  }
`;

const ProjectsTitle = styled.h1`
  font-size: 15px;
  color: #2d2c2c;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
