import { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import axiosInstance from '../services/axios';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('userData');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/users/login', {
        email,
        password
      });
      const userData = response.data;
      setUser(userData.user);
      localStorage.setItem('userData', JSON.stringify(userData.user));
    } catch (error) {
      throw error;
    }
  }

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userData');
    // localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, login, setUser, logout, loading }}>
    {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;