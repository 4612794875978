import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../services/axios';
import { AuthContext } from "../../contexts/AuthContext";
import { Drawer, Button, Divider, TextInput, Select, Space, MultiSelect, LoadingOverlay } from "@mantine/core";
import styled from "styled-components";
import { IconEye, IconEyeOff } from "@tabler/icons-react";


export const EditUserModal = ({ isEditingProfile, currentUser, opened, onClose, onUserUpdated }) => {
  const { user,setUser } = useContext(AuthContext);
  const [firstName, setFirstName] = useState(currentUser?.firstName || '');
  const [lastName, setLastName] = useState(currentUser?.lastName || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [password, setPassword] = useState(currentUser?.password || '');
  const [passwordHash, setPasswordHash] = useState(currentUser?.passwordHash || '');
  const [role, setRole] = useState(currentUser?.role || "");
  const [username, setUsername] = useState(currentUser?.username || '');
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(currentUser?.projects?.map(p => p?.projectId) || []);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/projects/all');
        setProjects(response?.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName || '');
      setLastName(currentUser.lastName || '');
      setEmail(currentUser.email || '');
      setPassword(currentUser.password);
      setPasswordHash(currentUser.passwordHash || '');
      setRole(currentUser.role || '');
      setUsername(currentUser.username || '');
    }
    setErrors({})
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && currentUser?.projects) {
      setSelectedProjects(currentUser?.projects.map(p => p?.projectId));
    }
  }, [currentUser]);

  console.log(currentUser,'Passwordii')

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const validate = () => {
    let validationErrors = {};
    if (!firstName.trim()) validationErrors.firstName = 'First name is required';
    if (!lastName.trim()) validationErrors.lastName = 'Last name is required';
    if (!email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!username.trim()) validationErrors.username = 'Username is required';
    if (password && password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters';
    }
    if (!role) validationErrors.role = 'Role is required';
  
    if (user?.role === 'superadmin' && selectedProjects.length === 0) {
      validationErrors.selectedProjects = 'At least one project must be selected';
    }
  
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  

  const updateUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const userData = {
        firstName,
        lastName,
        email,
        password,
        role,
        username,
        projects: selectedProjects
      };
      await axiosInstance.put(`/users/${currentUser.userId}`, userData);
   
      if (onUserUpdated) {
        await onUserUpdated();
      }

      if (isEditingProfile) {
        setUser(userData); // Update the user in the AuthContext
      }
      
      setLoading(false);
      onClose();

    } catch (error) {
      console.error(`Something went wrong: ${error}`);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (currentUser && currentUser.projects) {
      const filteredProjectIds = currentUser.projects
        .filter(p => p !== null && p !== undefined && p.projectId !== null && p.projectId !== undefined)
        .map(p => p.projectId);
      setSelectedProjects(filteredProjectIds);
    }
  }, [currentUser]);
  

  return (
    <>
      <Drawer position="right" opened={opened} onClose={onClose} title="Edit User" overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}>
        <LoadingOverlay visible={loading} />
        <Divider />
        <Space h={20} />
        <ProjectForm>
          <TextInput style={{ paddingBottom: "14px" }} label="First Name" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} error={errors.firstName} />
          <TextInput style={{ paddingBottom: "14px" }} label="Last Name" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} error={errors.lastName} />
          <TextInput style={{ paddingBottom: "14px" }} label="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} error={errors.email} />
          <TextInput style={{ paddingBottom: "14px" }} label="Username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} error={errors.username} />
          {/* <TextInput 
          style={{ paddingBottom: "14px" }} 
          type="password" 
          label="Password" 
          placeholder="At least 8 characters" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} error={errors.password} 
          /> */}
            <TextInput
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="At least 8 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            rightSection={
              <Button onClick={() => setShowPassword((prev) => !prev)} style={{ background: '#08024D', border: 'none', padding: 0 }}>
                {showPassword ? <IconEyeOff size={20} /> : <IconEye size={20} />}
              </Button>
            }
          />
          {!isEditingProfile && (
            <>
              <Select
                label="Role"
                placeholder="Select a role"
                data={["client", "team_member",user?.role == 'superadmin' ? 'superadmin' : '']}
                value={role}
                onChange={setRole}
                error={errors.role}
                clearable
              />
            <MultiSelect
              label="Select projects"
              placeholder="Pick projects"
              data={projects
                .filter(project => project && project.name)
                .map(project => ({ value: project.projectId, label: project.name }))
              }
              value={selectedProjects}
              onChange={setSelectedProjects}
              error={errors.selectedProjects}
              clearable
            />
            </>
          )}
        </ProjectForm>
        <Divider />
        <Space h={20} />
        <ButtonsContainer>
          <Button onClick={onClose} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: "#cfcdd6", color: "#08024d", fontWeight: 700 }}>Cancel</Button>
          <Button onClick={updateUser} disabled={loading} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: loading ? "#cfcdd6" : "#08024d", fontWeight: 700 }}>Save</Button>
        </ButtonsContainer>
      </Drawer>
    </>
  );
};

const ProjectForm = styled.div` display: flex; flex-direction: column; justify-content: center; `;
const ButtonsContainer = styled.div` display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 10px; `;
