import { useState, useEffect, useContext } from "react";

import { styled } from "styled-components";
import {
  Space,
  TextInput,
  Card,
  Avatar,
  Text,
  Button,
  Tooltip,
} from "@mantine/core";
import { IconSearch, IconPencil, IconLink } from "@tabler/icons-react";
import { DeleteCrewModal } from "../DeleteCrew/DeleteCrewModal";
import { EditTeamProfile } from "../EditTeamProfile/EditTeamProfile";
import { AddNewCrewModal } from "../AddNewCrewModal/AddNewCrewModal";
import Axios from "../../services/axios";
import { AuthContext } from "../../contexts/AuthContext";
export const CrewDetails = ({
  fetchTeamData,
  teamData,
  currentPage,
  perPage,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamProjects, setSelectedTeamProjects] = useState([]);
  const { user } = useContext(AuthContext);
  const [displayedProjects, setDisplayedProjects] = useState([]);

  useEffect(() => {
    if (fetchTeamData) fetchTeamData();
  }, [fetchTeamData]);

  const formatDocumentName = (key) => {
    if (!key) return "";

    const segments = key.split("-");
    const lastSegment = segments[segments.length - 1];
    return lastSegment;
  };

  const handleCardClick = (team) => {
    if (user.role === "team_member") {
      if (team.teamId === selectedTeamId) {
        setSelectedTeamId(null);
        setDisplayedProjects([]);
        setSelectedTeamProjects([]);
      } else {
        setSelectedTeamId(team.teamId);
        setDisplayedProjects(team.projects);
        setSelectedTeamProjects(team.projects);
      }
    }
  };

  const handleExploreClick = (itemUri) => {
    const bloomUrl = `https://bloom.neo4j.io/index.html?connectURL=${encodeURIComponent(
      itemUri
    )}`;
    window.open(bloomUrl, "_blank");
  };

  const filteredTeams = teamData?.filter(
    (team) =>
      (user?.role !== "team_member" ||
        team.members.some((member) => member?.userId === user?.userId)) &&
      (team.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        team.projects.some((project) =>
          project?.name?.toLowerCase().includes(searchValue.toLowerCase())
        ))
  );

  const startIndex = (currentPage - 1) * perPage;
  const paginatedTeams = filteredTeams?.slice(startIndex, startIndex + perPage);

  return (
    <Wrapper>
      <ProjectHeader>
        <ProjectsTitle>Teams</ProjectsTitle>
        <NewProject>
          <TextInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: "10em" }}
            leftSection={<IconSearch size={12} />}
            placeholder="Search"
            searchable
          />
          <AddNewCrewModal onTeamUpdated={fetchTeamData} />
        </NewProject>
      </ProjectHeader>
      <Space h={20} />
      <UserSection>
        {paginatedTeams && paginatedTeams.length > 0
          ? paginatedTeams.map((team) => (
              <CardContainer key={team.teamId}>
                <Tooltip
                  label={
                    user?.role == "team_member" ? " Click to show projects" : ""
                  }
                  position="bottom"
                  withArrow
                  disabled={user?.role == "team_member" ? false : true}
                >
                  <Card
                    withBorder
                    style={{
                      marginBottom: 16,
                      width: "100%",
                      boxShadow:
                        team.teamId === selectedTeamId
                          ? "0 4px 8px 0 rgba(0,0,0,0.2)"
                          : "none",
                    }}
                    onClick={() => handleCardClick(team)}
                  >
                    <MainDiv>
                      <DetailsDiv>
                        <Avatar
                          size={60}
                          src={team.avatarUrl}
                          alt={team.name}
                        />
                        <UserDetails>
                          <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                            Name
                          </Text>
                          <Text
                            style={{
                              fontWeight: 400,
                              fontSize: "13px",
                              paddingBottom: "5px",
                            }}
                          >
                            {team.name}
                          </Text>
                          <Space h={10} />
                          <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                            Description
                          </Text>
                          <Text
                            style={{
                              fontWeight: 400,
                              fontSize: "13px",
                              paddingBottom: "5px",
                            }}
                          >
                            {team.description}
                          </Text>
                          <Space h={10} />
                          <MembersProjectsContainer>
                            <MembersContainer>
                              <Text
                                style={{ fontWeight: 700, fontSize: "13px" }}
                              >
                                Members
                              </Text>
                              {team.members
                                .filter(
                                  (member) =>
                                    member &&
                                    member.userId &&
                                    member.firstName &&
                                    member.lastName
                                )
                                .map((el) => (
                                  <Text
                                    key={el.userId}
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "13px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    {el.firstName + " " + el.lastName}
                                  </Text>
                                ))}
                            </MembersContainer>

                            {team.teamId === selectedTeamId && user.role && (
                              <ProjectsContainer>
                                <Text
                                  style={{ fontWeight: 700, fontSize: "13px" }}
                                >
                                  Projects
                                </Text>
                                {team.projects.map((el) => (
                                  <Text
                                    key={el.projectId}
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "13px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    {el?.name}
                                  </Text>
                                ))}
                              </ProjectsContainer>
                            )}
                          </MembersProjectsContainer>
                        </UserDetails>
                      </DetailsDiv>
                      <ButtonsDiv>
                        {user?.role === "team_member" ? null : (
                          <>
                            <EditTeamProfile
                              team={team}
                              onTeamUpdated={fetchTeamData}
                            />
                            <DeleteCrewModal
                              teamId={team?.teamId}
                              onTeamUpdated={fetchTeamData}
                            />
                          </>
                        )}
                      </ButtonsDiv>
                    </MainDiv>
                  </Card>
                </Tooltip>
              </CardContainer>
            ))
          : ""}

        <ProjectCards>
          {displayedProjects?.map((item, index) => (
            <Card
              withBorder
              key={index}
              style={{ marginBottom: 16, width: "35em" }}
            >
              <Card.Section withBorder style={{ padding: "10px" }}>
                <MainSection>
                  <Text
                    style={{
                      padding: "8px",
                      fontWeight: 700,
                      fontSize: "13px",
                    }}
                  >
                    {item?.name}
                  </Text>
                </MainSection>
              </Card.Section>
              <Card.Section style={{ padding: "8px" }} withBorder>
                <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                  Description
                </Text>
                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    paddingBottom: "5px",
                  }}
                >
                  {item.description}
                </Text>
                <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                  Owned By:
                </Text>
                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    paddingBottom: "5px",
                  }}
                >
                  Dialectiq
                </Text>
              </Card.Section>
              <Card.Section style={{ padding: "8px" }} withBorder>
                <Credentials>
                  <div>
                    <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                      Username{" "}
                      <span style={{ fontWeight: 400 }}>{item.username}</span>
                    </Text>
                  </div>
                  <div>
                    <Text style={{ fontWeight: 700, fontSize: "13px" }}>
                      Password{" "}
                      <span style={{ fontWeight: 400 }}>{item.password}</span>
                    </Text>
                  </div>
                </Credentials>
              </Card.Section>
              <Card.Section style={{ padding: "10px" }}>
                <Button
                  leftSection={<IconLink size={15} />}
                  onClick={() => handleExploreClick(item.uri)}
                  style={{
                    backgroundColor: "#dfdee9",
                    height: "30px",
                    marginRight: "5px",
                    fontSize: "12px",
                    color: "#08024d",
                  }}
                >
                  Explore
                </Button>
                {item?.documents?.map((el, docIndex) => (
                  <a
                    href={el?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      leftSection={<IconLink size={15} />}
                      style={{
                        backgroundColor: "#dfdee9",
                        height: "30px",
                        marginRight: "5px",
                        fontSize: "12px",
                        color: "#08024d",
                      }}
                    >
                      {formatDocumentName(el?.key)}
                    </Button>
                  </a>
                ))}
              </Card.Section>
            </Card>
          ))}
        </ProjectCards>
      </UserSection>
      <Space h={30} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 70px;
`;

const CardContainer = styled.div`
  width: 45%;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    width: 45%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-grow: 1;
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 2em;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const MembersProjectsContainer = styled.div`
  display: flex;
  gap: 2em;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProjectsTitle = styled.h1`
  font-size: 2em;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const NewProject = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Credentials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
`;

const ProjectCards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
`;
