import { useState, useEffect } from "react";
import styled from "styled-components";
import { Space, TextInput, Loader } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { AddNewUserModal } from "../AddNewUser/AddNewUserModal";
import { UsersTable } from "../UsersTable/UsersTable";

export const TeamDetails = ({
  users,
  onEditUser,
  currentPage,
  perPage,
  userUpdated,
  setTotalUsers,
  loading,
  data,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [modalClosed, setModalClosed] = useState(false);

  const handleUserAdded = () => {
    data();
    setModalClosed((prevRefresh) => !prevRefresh);
  };

  const handleModalClosed = () => {
    setModalClosed((prevState) => !prevState);
  };
  const refreshUsers = modalClosed || userUpdated;

  return (
    <Wrapper>
      {loading ? (
        <StyledLoader>
          <Loader size={60} color="#08024d" />
        </StyledLoader>
      ) : (
        <>
          <ProjectHeader>
            <ProjectsTitle>Clients</ProjectsTitle>
            <NewProject>
              <TextInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ width: "10em" }}
                leftSection={<IconSearch size={12} />}
                placeholder="Search"
              />
              <AddNewUserModal
                onUserAdded={handleUserAdded}
                onModalClose={handleModalClosed}
              />
            </NewProject>
          </ProjectHeader>
          <Space h={30} />
          <UsersTable
            users={users}
            searchValue={searchValue}
            currentPage={currentPage}
            perPage={perPage}
            onEditUser={onEditUser}
            refreshUsers={refreshUsers}
            totalUsers={100}
            data={data}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 70px;
`;

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
`;

const ProjectsTitle = styled.h1`
  font-size: 15px;
  color: #2d2c2c;
`;

const NewProject = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledLoader = styled.div`
  display: flex;
  padding-top: 5em;
  justify-content: center;
  align-items: center;
`;
