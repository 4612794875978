import { styled } from "styled-components";
import { LoginForm } from "../../components/LoginForm/LoginForm";
import { useLocation } from 'react-router-dom';

export const LoginPage = () => {
  const location = useLocation();
  const fromProtectedRoute = location.state?.fromProtectedRoute;

  return (
    <Wrapper>
      <img
        width={240}
        height={50}
        src="../../assets/dialectIQ.png"
        alt="dialectIQ"
      />
      {fromProtectedRoute && (
        <Message>
          Your session has expired. Please log in again.
        </Message>
    )}
      <LoginForm />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 17rem;
`;

const Message = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;