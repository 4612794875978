import { useState, useEffect, useContext } from 'react';
import { useDisclosure } from "@mantine/hooks";
import {
  Drawer,
  Select,
  Button,
  Divider,
  TextInput,
  Space,
  MultiSelect,
  LoadingOverlay
} from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import styled from "styled-components";
import axiosInstance from "../../services/axios";
import { AuthContext } from "../../contexts/AuthContext";

export const AddNewUserModal = ({ onUserAdded, onModalClose }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordHash, setPasswordHash] = useState('');
  const [role, setRole] = useState("");
  const [username, setUsername] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState(false);


  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = () => {
    let validationErrors = {};
    if (!firstName.trim()) validationErrors.firstName = 'First name is required';
    if (!lastName.trim()) validationErrors.lastName = 'Last name is required';
    if (!email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!username.trim()) validationErrors.username = 'Username is required';
    if (!password.trim()) {
      validationErrors.password = 'Password is required';
    } else if (password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters';
    }
    if (!role) validationErrors.role = 'Role is required';

    if(user?.role === 'superadmin'){
      if (selectedProjects.length === 0) validationErrors.selectedProjects = 'At least one project must be selected';

    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };


  const createUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const newUser = {
        firstName,
        lastName,
        email,
        password,
        passwordHash,
        role,
        username,
        clientId: user?.userId,
        projects: selectedProjects  // Include the selected project IDs
      };
  
      await axiosInstance.post("/users/", newUser);
      close();
      onUserAdded();
  
    } catch (error) {
      console.error("Error creating user:", error);
      setError('Email already exists')

      // setError(error)
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    close();
    if (onModalClose) onModalClose(); 
    setError("") // Call onModalClose when the modal is closed
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/projects/all');
        const allProjects = response?.data || [];

        // If the user is a client, filter the projects to show only their projects
        if (user?.role === 'client') {
          const userProjectIds = new Set(user.projects.map(p => p.projectId));
          const userProjects = allProjects.filter(project => userProjectIds.has(project.projectId));
          setProjects(userProjects);
        } else {
          setProjects(allProjects);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [user]);

  useEffect(() => {
    if (!opened) {
      onModalClose();
    }
  }, [opened]);

  console.log(user?.role,'ROLE')

  
  
  const roles = user?.role === 'superadmin' 
  ? ["superadmin", "client", "team_member"]
  : ["team_member"];

  return (
    <>
      <Drawer position="right" opened={opened} onClose={handleClose} title="New User" overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}>
        <LoadingOverlay visible={loading} />
        <Divider />
        <Space h={20} />
        <ProjectForm>
        <TextInput
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={errors.firstName}
          />
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={errors.lastName}
          />
          <TextInput
            label="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
          <TextInput
            label="Username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.username}
          />
          <TextInput
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="At least 8 characters"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}
            rightSection={
              <Button onClick={() => setShowPassword((prev) => !prev)} style={{ background: '#08024D', border: 'none', padding: 0 }}>
                {showPassword ? <IconEyeOff size={20} /> : <IconEye size={20} />}
              </Button>
            }
          />


          <Select
            label="Role"
            placeholder="Select a role"
            data={roles}
            value={role}
            onChange={setRole}
            error={errors.role}
            clearable
          />
            <MultiSelect
            label="Select projects"
            placeholder="Pick projects"
            data={projects?.map(project => ({ value: project.projectId, label: project.name }))}
            value={selectedProjects}
            onChange={setSelectedProjects}
            error={errors.selectedProjects}
            clearable
          />
          {/* {user?.role == 'superadmin' ? 
            <MultiSelect
            label="Select projects"
            placeholder="Pick projects"
            data={projects?.map(project => ({ value: project.projectId, label: project.name }))}
            value={selectedProjects}
            onChange={setSelectedProjects}
            error={errors.selectedProjects}
            clearable
          />
          : ""} */}
        
        <p style={{color:'red'}} >
        {error && error}
        </p>
  
        </ProjectForm>
        <Divider />
        <Space h={20} />
        <ButtonsContainer>
          <Button onClick={close} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: "#cfcdd6", color: "#08024d", fontWeight: 700 }}>Cancel</Button>
          <Button onClick={createUser} disabled={loading} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: loading ? "#cfcdd6" : "#08024d", fontWeight: 700 }}>Create</Button>
        </ButtonsContainer>
      </Drawer>
      <Button onClick={open} style={{ width: "10em", borderRadius: "4px", backgroundColor: "#08024d", fontWeight: 700 }}>+ New User</Button>
    </>
  );
};

const ProjectForm = styled.div` display: flex; flex-direction: column; justify-content: center; `;
const ButtonsContainer = styled.div` display: flex; flex-direction: row; justify-content: center; align-items: center; gap: 10px; `;
