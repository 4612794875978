import { useEffect, useState, useContext, useCallback } from "react";
import { AppShell, Pagination, LoadingOverlay } from "@mantine/core";
import styled from "styled-components";
import { usePagination } from "../../contexts/PaginationContext";
import Axios from "../../services/axios";
import { CrewDetails } from "../../components/CrewDetails/CrewDetails";
import { Layout } from "../../layout/Layout";
import { AuthContext } from "../../contexts/AuthContext";
export const CrewPage = () => {
  const { onPageChange } = usePagination();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const fetchTeamData = useCallback(async () => {
    // Removed currentPage, perPage from dependencies
    if (!user) return; // Exit if user data is not available
    try {
      let endpoint =
        user?.role === "superadmin" || user?.role === "team_member"
          ? "/teams/all"
          : `/teams/${user?.userId}`;
      const response = await Axios.get(endpoint);
      setTeamData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching team data:", error);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchTeamData();
  }, []);

  return (
    <Layout>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <CrewDetails
        fetchTeamData={fetchTeamData}
        teamData={teamData}
        loading={loading}
        perPage={perPage}
        currentPage={currentPage}
      />
      <div style={{ paddingTop: "50px" }}></div>
      <AppShell.Footer h={60}>
        <PaginationDiv>
          <Pagination
            color="#08024d"
            total={Math.ceil(teamData?.length / perPage)}
            page={currentPage}
            onChange={setCurrentPage}
          />
        </PaginationDiv>
      </AppShell.Footer>
    </Layout>
  );
};

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: "10em";
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
