import { useContext, useState } from "react";
import { AppShell, Avatar, Burger } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { AuthContext } from "../contexts/AuthContext";
import { EditUserModal } from "../components/EditUserModal/EditUserModal";

export const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure();
  const { user, logout } = useContext(AuthContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);

  const openEditModal = () => {
    setIsEditingProfile(true);
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => {
    setIsEditingProfile(false);
    setIsEditModalOpen(false);
  };
  const role = user?.role;

  const handleLogout = () => {
    logout();
    navigate(`/login`);
  };

  const navItems = [
    { path: "/home", label: "Projects", icon: "../../assets/home.png" },
    { path: "/clients", label: "Clients", icon: "../../assets/user.png" },
    { path: "/teams", label: "Teams", icon: "../../assets/user.png" },
    {
      path: "",
      label: "Support",
      icon: "../../assets/lifebuoy.png",
      externalLink: "https://dialectiq.org/contact/",
    },
  ];

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: { base: 80, sm: 200, lg: 260 },
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Navbar p="md">
    
          <ImageDiv>
            <Link to="/home">
              <img width={150} src="../../assets/dialectIQ.png" alt="dialectIQ" />
            </Link>
          </ImageDiv>
      
        <LinkItems>
          {navItems.map((item, index) => {
            if (role === "team_member" && item.label === "Clients") {
              return null;
            }
            if (role !== "superadmin" && item.label === "Clients") {
              item.label = "Users";
            }
            return (
              <div
                key={index}
                className={`link-item ${
                  location.pathname === item.path ? "active" : ""
                }`}
              >
                <LinkItem>
                  <Avatar size={26} src={item.icon} />
                  <Link
                    className={`link-item ${
                      location.pathname === item.path ? "active" : ""
                    }`}
                    to={item.path === "" ? item.externalLink : item.path}
                    {...(item.externalLink
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : {})}
                  >
                    {item.label}
                  </Link>
                </LinkItem>
              </div>
            );
          })}
        </LinkItems>
      </AppShell.Navbar>
      <AppShell.Header h={60}>
        <HeaderItems>
          <Burger
            style={{ paddingLeft: "20px", paddingTop: "30px" }}
            opened={opened}
            onClick={toggle}
            hiddenFrom="sm"
            size="sm"
          />
          <SpaceH1 />
          <Logout>
            <h1
              onClick={openEditModal}
              style={{
                textTransform: "capitalize",
                marginRight: "1em",
                fontSize: "12.5px",
                fontWeight: 700,
                color: "#2d2c2c",
                marginTop: "22px",
                cursor: "pointer",
              }}
            >
              {user?.firstName + " " + user?.lastName}
            </h1>

            <IconLogout
              onClick={handleLogout}
              style={{
                marginTop: "14px",
                marginRight: "1em",
                cursor: "pointer",
              }}
              size={16}
            />
            <h1
              onClick={handleLogout}
              style={{
                textTransform: "capitalize",
                marginRight: "1em",
                fontSize: "12.5px",
                fontWeight: 700,
                color: "#2d2c2c",
                marginTop: "22px",
                cursor: "pointer",
              }}
            >
              Log out
            </h1>
          </Logout>
          <EditUserModal
            opened={isEditModalOpen}
            currentUser={user}
            onClose={closeEditModal}
            isEditingProfile={isEditingProfile}
          />
        </HeaderItems>
      </AppShell.Header>
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};

const SpaceH1 = styled.h1`
  margin-left: 23em;
  font-size: "12.5px";
  font-weight: 500;
  color: "#2d2c2c";
  margin-top: "22px";
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const ImageDiv = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Logout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const LinkItems = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  .link-item {
    padding: 4px;
    cursor: pointer;
    color: #2d2c2c;
    font-weight: 700;
    font-size: 14px;

    &.active {
      background-color: #08024d;
      border-radius: 10px;
      width: 15em;
      color: white;
    }
    a {
      text-decoration: none;
    }
  }
  @media (max-width: 768px) {
    padding-left: 3em;
    .link-item {
      padding: 4px;
      cursor: pointer;
      color: #2d2c2c;
      font-weight: 700;
      font-size: 14px;

      &.active {
        background-color: #08024d;
        border-radius: 10px;
        width: 20em;
        color: white;
      }
      a {
        text-decoration: none;
      }
    }
  }
`;
