import axios from 'axios';

// const prodUrl = 'https://7n047ivys2.execute-api.us-east-1.amazonaws.com/dev/'
const prodUrl = 'https://gjx0qu6lg8.execute-api.us-east-1.amazonaws.com/dev'
const localUrl = 'http://localhost:3002/'

const axiosInstance = axios.create({
  baseURL: prodUrl,
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     config.headers['Accept'] = 'application/json';
//     return config;
//   },
//   (error) => Promise.reject(error),
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && (error.response.status === 401 || error.response.status === 403)) {
//       // If we get a 401 or 403 status code, token is expired or invalid.
//       // You can handle logout here, like remove the token from local storage.
//       localStorage.removeItem('token');

//       // Then throw an error that you can catch in your UI components and redirect to login page
//       throw new Error('Token expired or invalid, please log in again.');
//     }

//     // If the error is not 401 or 403, just reject as is
//     return Promise.reject(error);
//   },
// );

export default axiosInstance;

