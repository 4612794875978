import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import styled from "styled-components";
import { Router } from "./routes/Router";
import AuthProvider from "./contexts/AuthContext";
import { PaginationProvider } from "./contexts/PaginationContext";
const StyledDiv = styled.div`
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;




export const App = () => {
  return (
    <StyledDiv>
      <MantineProvider>
        <AuthProvider>
          <PaginationProvider>
            <Router />
          </PaginationProvider>
        </AuthProvider>
      </MantineProvider>
    </StyledDiv>
  );
};

export default App;
