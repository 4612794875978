import { useState, useEffect, useContext } from "react";
import { EditProjectModal } from "../../components/EditProjectModal/EditProjectModal";
import { HomeProjects } from "../../components/HomeProjects/HomeProjects";
import { Layout } from "../../layout/Layout";
import { styled } from "styled-components";
import { AppShell, Pagination } from "@mantine/core";
import axios from "../../services/axios";
import { AuthContext } from "../../contexts/AuthContext";

export const HomePage = () => {
  const perPage = 10;
  const [Idata, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const [selectedProject, setSelectedProject] = useState({
    project: null,
    open: false,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const handleEditProject = (project) => {
    setSelectedProject({
      project: project,
      open: true,
    });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/projects/all`);

      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <Layout>
      <HomeProjects
        currentPage={currentPage}
        perPage={perPage}
        onEditProject={handleEditProject}
        Idata={Idata}
        loading={loading}
        fetchData={fetchData}
      />
      <EditProjectModal
        instance={selectedProject?.project}
        opened={selectedProject.open}
        refreshData={fetchData}
        onClose={() => {
          setSelectedProject({
            project: null,
            open: false,
          });
          fetchData();
        }}
      />
      <div style={{ paddingTop: "50px" }}></div>
      <AppShell.Footer h={60}>
        <PaginationDiv>
          <Pagination
            color="#08024d"
            onChange={handlePageChange}
            value={currentPage}
            total={Math.ceil(Idata.length / perPage)} // You'll have to adjust this part to make sure Idata is accessible here.
          />
        </PaginationDiv>
      </AppShell.Footer>
    </Layout>
  );
};

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: "10em";
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
