import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { styled } from "styled-components";
import Axios from "../../services/axios";
export const DeleteCrewModal = ({ teamId, onTeamUpdated }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const handleDelete = async () => {
    try {
      const response = await Axios.delete(`/teams/${teamId}`);

      if (response.status === 200) {
        close(); // Close the modal
        if (onTeamUpdated) onTeamUpdated(); // Call the callback function if provided
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <Modal opened={opened} onClose={close}>
        <Wrapper>
          <DeleteTitle>Delete</DeleteTitle>
          <Paragraph>Are you sure you want to delete this team?</Paragraph>
          <ButtonsContainer>
            <Button
              onClick={close}
              style={{
                width: "14em",
                height: "3em",
                borderRadius: "4px",
                backgroundColor: "#cfcdd6",
                color: "#08024d",
                fontWeight: 700,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              style={{
                width: "14em",
                height: "3em",
                borderRadius: "4px",
                backgroundColor: "#b04357",
                fontWeight: 700,
              }}
            >
              Delete
            </Button>
          </ButtonsContainer>
        </Wrapper>
      </Modal>
      <IconTrash onClick={open} size={22} style={{ cursor: "pointer" }} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DeleteTitle = styled.h1`
  font-size: 15px;
  color: #2d2c2c;
`;

const Paragraph = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 50px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
