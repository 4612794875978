import React, { useState, useEffect } from 'react';
import { Drawer, Button, Divider, TextInput, Text, Space, LoadingOverlay } from "@mantine/core";
import styled from "styled-components";
import axiosInstance from '../../services/axios';
import { FileUpload } from "../Dropzone/Dropzone";

const DocumentList = ({ documents, onRemove }) => (
  <DocumentsContainer>
    {documents && documents.length > 0 ? documents.map((document) => (

      <DocumentCard key={document.key}>
        <a href={document.url} target="_blank" rel="noopener noreferrer">
        {document.key}
        </a>
        <Button color="red" onClick={() => onRemove(document.key)}>Remove</Button>
      </DocumentCard>
    )) : <Text>No documents available</Text>}
  </DocumentsContainer>
);



const formatDocumentName = (key) => {
  if (!key) return '';
  
  const segments = key.split('-');
  const lastSegment = segments[segments.length - 1];
  return lastSegment;
};

export const EditProjectModal = ({ instance, opened, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    uri: '',
    username: '',
    password: '',
    description: '',
  });



  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [documentsToRemove, setDocumentsToRemove] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let validationErrors = {};
    if (!formData.name.trim()) validationErrors.name = "Name is required";
    if (!formData.description.trim()) validationErrors.description = "Description is required";
    if (!formData.uri.trim()) validationErrors.uri = "URL is required";
    if (!formData.username.trim()) validationErrors.username = "Username is required";
    if (!formData.password.trim()) validationErrors.password = "Password is required";
    return validationErrors;
  };
  const handleSubmit = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    try {
      const updatedData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        updatedData.append(key, value);
      });
  
      newFiles.forEach(file => {
        updatedData.append('document', file);
      });
  
      documentsToRemove.forEach(documentKey => {
        updatedData.append('documentsToRemove', documentKey);
      });
  
      const response = await axiosInstance.put(`/projects/${instance.projectId}`, updatedData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.status === 200) {
        // Clear newFiles and documentsToRemove states after successful request
        setNewFiles([]);
        setDocumentsToRemove([]);
        onClose();
      } else {
        setErrorMessage("Failed to update the project details. Please try again.");
      }
    } catch (err) {
      let errorMsg = err.response && err.response.data ? err.response.data.message : "Failed to update the project. Please try again.";
      setErrorMessage(errorMsg);
    }
    setLoading(false);
  };
  
  const handleRemoveDocument = (documentKey) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file.key !== documentKey));
    setDocumentsToRemove(prev => [...prev, documentKey]);
  };

  const handleNewFilesAdded = (files) => {
    setNewFiles(prevFiles => [...prevFiles, ...files]);
  };




  useEffect(() => {
    if (instance) {
      setFormData({
        name: instance.name || '',
        uri: instance.uri || '',
        username: instance.username || '',
        password: instance.password || '',
        description: instance.description || '',
      });
      setUploadedFiles(instance.documents || []);
    }

  }, [instance]);

  useEffect(() => {
    if (!opened) {
      setErrors({});
      setErrorMessage('');
    }
  }, [opened]);

  return instance && (
    <Drawer
      position="right"
      opened={opened}
      onClose={onClose}
      title="Edit Project"
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
    >
      <LoadingOverlay visible={loading} />
      <Divider />
      <Space h={20} />
      <ProjectForm>
        <TextInput 
          label="Project Name" 
          placeholder="Name" 
          value={formData.name} 
          onChange={handleChange} 
          name="name" 
          error={errors.name}
        />
      <TextInput 
        label="Description" 
        placeholder="Description" 
        value={formData.description} 
        onChange={handleChange} 
        name="description"
        error={errors.description}
      />

        <TextInput 
          label="URL" 
          placeholder="URL" 
          value={formData.uri} 
          onChange={handleChange} 
          name="uri" 
          error={errors.uri}
        />
        <TextInput 
          label="Username" 
          placeholder="Username" 
          value={formData.username} 
          onChange={handleChange} 
          name="username" 
          error={errors.username}
        />
        <TextInput 
          label="Password" 
          placeholder="Password" 
          value={formData.password} 
          onChange={handleChange} 
          name="text" 
          type="text" 
          error={errors.password}
        />
      </ProjectForm>
      <FileUpload onFilesAdded={handleNewFilesAdded} />
      <Text size="md" weight={500}>Current Documents</Text>
      <DocumentList documents={uploadedFiles} onRemove={handleRemoveDocument} />
      <Text size="md" weight={500}>New Documents</Text>
      {newFiles.map((file, index) => (
        <div key={index}>
          <Text>{formatDocumentName(file.name)}</Text>
        </div>
      ))}
      <Divider />
      <Space h={20} />
      <ButtonsContainer>
        <Button onClick={onClose} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: "#cfcdd6", color: "#08024d", fontWeight: 700 }}>Cancel</Button>
        <Button onClick={handleSubmit} style={{ width: "14em", height: "3em", borderRadius: "4px", backgroundColor: loading ? "#cfcdd6" : "#08024d", fontWeight: 700 }}>Save</Button>
      </ButtonsContainer>
      {errorMessage && <Text color="red">{errorMessage}</Text>}
    </Drawer>
  );
};

const ProjectForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DocumentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

export default EditProjectModal;
