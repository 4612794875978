import { TextInput, Space, Input, Button } from "@mantine/core";
import { IconLock, IconUser } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext"; // Make sure to import this or adjust the path

export const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState(null);
  const { user, login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined) {
      navigate('/login'); // Replace '/login' with the correct path to your login page if it's different
    }
    if (user) {
      if (user.role === 'superadmin') {
        navigate('/home');
      } else {
        navigate('/teams');
      }
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      await login(username.trim(), password.trim());
    } catch (error) {
      console.error(error);
      setError("Email or Password is invalid, please provide the correct credentials");
    }
  };

  const handleNavigate = () => {
    navigate('/forgot-password');
  };

  return (
    <>
      <Space h={30} />
      <TextInput
        style={{ width: "340px" }}
        value={username}
        onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert the input value to lowercase
        placeholder="Email"
        leftSection={<IconUser size={16} />}
      />
      <Space h={9} />
      <Input
        style={{ width: "340px" }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Password"
        leftSection={<IconLock size={16} />}
      />
      <Space h={9} />
      <Button
        onClick={handleLogin}
        style={{
          width: "340px",
          height: "45px",
          backgroundColor: "#08024d",
          fontWeight: 700,
        }}
      >
        Login
      </Button>
      {errorMessage && (
        <Paragraph style={{ color: "red", textAlign: "center" }}>
          {errorMessage}
        </Paragraph>
      )}
      <Space h={8} />
      <ForgotPasswordDiv onClick={handleNavigate}>
        <Paragraph>Forgot your password?</Paragraph>
      </ForgotPasswordDiv>
    </>
  );
};

const ForgotPasswordDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Paragraph = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 12px;
`;
