import { useState, useEffect, useContext } from "react";
import { Table } from "@mantine/core";
import { DeleteUserModal } from "../DeleteUserModal/DeleteUserModal";
import { IconPencil } from "@tabler/icons-react";
import { styled } from "styled-components";
import { AuthContext } from "../../contexts/AuthContext";

export const UsersTable = ({
  users,
  data,
  onEditUser,
  currentPage,
  perPage = 1,
  searchValue,
  refreshUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  const rows = users.map((people) => {
    // Skip rendering the row if the user's role is superadmin
    if (people?.userId === user?.userId) return null;

    return (
      <Table.Tr key={people.userId}>
        <Table.Td>{people.firstName}</Table.Td>
        <Table.Td>{people.lastName}</Table.Td>
        <Table.Td>{people.username}</Table.Td>
        <Table.Td>{people.email}</Table.Td>
        <Table.Td>{people?.role}</Table.Td>
        <Table.Td>{people?.createdAt}</Table.Td>

        <Table.Td>
          <ButtonsDiv>
            <IconPencil
              onClick={() => onEditUser(people)}
              size={22}
              style={{ cursor: "pointer" }}
            />
            <DeleteUserModal userId={people.userId} afterUserDelete={data} />
          </ButtonsDiv>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <ScrollableTableContainer>
        <Table withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>First Name</Table.Th>
              <Table.Th>Last Name</Table.Th>
              <Table.Th>Username</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>CreatedAt</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollableTableContainer>
    </>
  );
};

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScrollableTableContainer = styled.div`
  overflow-x: auto;

  @media (max-width: 768px) {
    -webkit-overflow-scrolling: touch;
  }
`;
